// Main JS file

// DOM ready
$(function () {

    var $body = $('body'),
        $nav = $('.nav'),
        $navToggle = $('.header .nav-toggle'),
        navOpenClass = 'nav-open',
        touchDeviceClass = 'is-touch'
    ;

    // Detect touch device
    $(window).on('touchstart', function () {
        $body.addClass(touchDeviceClass);
    });

    // Header panel toggle
    function toggleNav(visible) {
        if (visible) {
            $body.addClass(navOpenClass);
        } else {
            $body.removeClass(navOpenClass);
        }
    }

    // Nav toggle
    $navToggle.on('click', function(e) {
        e.preventDefault();
        toggleNav(!$body.hasClass(navOpenClass));
    });

    // Global Esc press
    $(document).on('keyup', function(e) {
        if (e.keyCode === 27) { // Esc
            toggleNav(false);
        }
    });

    // Global click outside menu/search
    $(document).on('click touchend', function(e) {
        if (!$(e.target).closest('.header').length) {
            toggleNav(false);
        }
    });

    // Go to top
    (function() {
        var $top = $('.top'),
            fixedClass = 'top--fixed';

        $(window).on('scroll', function(e) {
            $top.toggleClass(fixedClass, window.pageYOffset > 200);
        });
    })();

    // Counters
    (function () {
        $('[data-count]').each(function(i, el) {
            $(el).text(el.dataset.from);

            startAnimation(el);

            $(window).on('scroll', function(e) {
                startAnimation(el);
            });
        });

        function startAnimation(el) {
            if (window.pageYOffset > ($(el).position().top + $(el).height()) + 100) {
                if (!$(el).hasClass('animated')) {
                    count($(el));
                }
            }
        }

        function count($element) {
            $element.addClass('animated');
            $element.animate({
                counter: $element.data('to')
            }, {
                duration: 1500,
                easing: 'swing',
                step: function(now) {
                    $(this).text(numberWithSpaces(Math.ceil(now)));
                }
            });
        }
        function numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    })();

});
